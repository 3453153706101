/* BASICS */
html,
body {
  @media (max-width: 992px) {
    margin-top: 70px;
  }
}

.w100 {
  width: 100%;
}

/* SPACINGS */
.spc-large {
  margin-top: 2rem!important;
}

.spc-medium {
  margin-top: 1.5rem!important;
}

.spc-small {
  margin-top: 1rem!important;
}

.spc-xsmall {
  margin-top: 0.5rem!important;
}

.spc-xxsmall {
  margin-top: 0.25rem!important;
}

/* CONTAINER */
.container {
  margin-top: 50px;

  .row {
    position: relative;
  }
}

/* BOXED */
.boxed {
  padding: 50px 45px 50px 45px;

  p {
    margin: 10px 0 0 0;
  }

  .quote {
    font-size: 22px;
    line-height: 28px;
    margin: 0;
    color: #34849d;
  }
}

/* ARTICLE */
article {
  border-top: 1px solid $beige;
  margin: 25px 0 0 0;
  padding: 20px 0 0 0;

  &:first-of-type {
    margin: 0;
    border-top: 0px;
  }

  h3 {
    margin: 5px 0 0 0;
  }
}

/* BACKGROUND COLORS */
.stormy-blue {
  background-color: $stormy-blue;
}

.beige {
  background-color: $beige;
}

.light-peach {
  background: rgb(238, 236, 237);
  background: -moz-linear-gradient(180deg, rgba(238, 236, 237, 1) 0%, rgba(214, 208, 206, 1) 100%);
  background: -webkit-linear-gradient(180deg, rgba(238, 236, 237, 1) 0%, rgba(214, 208, 206, 1) 100%);
  background: linear-gradient(180deg, rgba(238, 236, 237, 1) 0%, rgba(214, 208, 206, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#eeeced", endColorstr="#d6d0ce", GradientType=1);
}

.light-peach-two {
  background-color: $light-peach-two;
}

.light-blue-grey-two {
  background-color: $light-blue-grey-two;
}

/* TEXT COLORS */
.white {
  color: #fff !important;
}

/* BUTTONS */
.btn {
  margin: 20px 0 0 0;
  padding: 0;
  font-family: 'PT Sans', Arial, sans-serif;
  font-weight: bold;
  font-size: 16px;
  color: $dull-orange;
  line-height: 16px;
  text-align: left;
  border-bottom: 2px solid transparent;
  -webkit-transition: all .2s ease-in-out;
  -moz-transition: all .2s ease-in-out;
  -o-transition: all .2s ease-in-out;
  -ms-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;

  &:after {
    background-image: url(/dist/img/icons/arrow-right.svg);
    background-size: 5px 8px;
    display: inline-block;
    width: 5px;
    height: 8px;
    content: "";
    margin-left: 5px;
  }

  &:hover {
    color: $dull-orange;
    border-bottom: 2px solid $dull-orange;
  }
}

.button {
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  background: transparent;
  border: none;
  color: $dull-orange;
  background-color: #5d514b;
  border-radius: 5px;
  display: inline-block;

  &:hover {
    background: $stormy-blue;
    color: white;
  }
}

.button-medium {
  padding: 10px 20px;
}

.button-small {
  padding: 4px 16px;
}

/* LIST */
ul {
  padding: 0 0 0 16px;
  font-family: 'PT Sans', Arial, sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  color: $greyish-brown-two;
  list-style-position: outside;

  li {
    list-style: none;
    margin-top: 10px;

    &::before {
      content: '...';
      color: $muted-blue;
      margin: 0 0 0 -16px;
    }
  }
}

ol {
  padding: 0 0 0 16px;
  font-family: 'PT Sans', Arial, sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  color: $greyish-brown-two;
  list-style-position: outside;

  li {
    list-style: none;
    margin-top: 10px;

    &::before {
      content: '\2022';
      color: $muted-blue;
      margin: 0 0 0 -12px;
    }
  }
}

/* MARGINS */
.m0 {
  margin: 0;
}

.p80 {
  padding: 0 80px;
}

@media (max-width: 768px) {
  .p80 {
    padding: 0px !important;
  }
}

/* PAGINATION */
.page-navigation {
  position: absolute;
  bottom: -90px;

  &.previous {
    left: 15px;
    right: unset;
    transform: rotate(-180deg);
  }

  &.next {
    right: 15px;
    left: unset;
  }

  @media (min-width: 992px) {
    top: 200px;
    bottom: unset;

    &.next {
      right: -40px;
      left: unset;
    }

    &.previous {
      left: -40px;
    }

    display: block;
  }
}


/* TEAM */
#team {
  text-align: center;

  h2 {
    margin-top: 20px;
  }

  img {
    margin-top: 40px;
  }

  .left {
    text-align: left;
  }

  .portrait {
    border: 1px solid transparent;
    border-radius: 50%;
    overflow: hidden;
  }
}

/* COOKIE BAR */
.cookieBar {
  bottom: 0;
  left: 0;
  position: fixed;
  z-index: 100 !important;
  right: 0;
  width: 100%;
  height: auto;
  padding: 40px 0;
  transform: translateY(100%);
  transition: transform 500ms ease-in-out;

  .container {
    margin-top: 0;
  }

  h2 {
    margin: 0;
  }

  button {
    background: transparent;
    border: none;
    color: $dull-orange;
    margin: 0;
    transition: transform 300ms ease;
    background-color: #5d514b;
    border-radius: 5px;
    padding: 10px 20px;

    &:hover {
      transform: scale(1.07);
      transform-origin: center;
    }
  }

  #decline {
    border: none !important;
    background: none !important;
    font-size: 14px;
  }

  &.fade-in {
    transform: translateY(0%);
  }
}

/* responsive video */
.responsive-video {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 25px;
  height: 0;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}