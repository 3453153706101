#navigation {
  margin-top: 25px;
  background-color: #fff;
  z-index: 1;
  @media (max-width: 992px) {
    margin-top: -140px;
    height: 140px;
    max-width: 100%;
    position: fixed;
  }

  .social {
    text-align: right;
    display: none;
    a {
      margin-left: 5px;
    }
  }

  nav.mobile {
    max-height: 0;
    width: 100%;
    position: absolute;
    top: 122px;
    left: 0;
    background-color: #fff;
    z-index: 99;
    padding: 0 45px;
    transition: all 300ms ease;
    overflow: scroll;

    .item {
      transition: all 300ms ease;
      position: relative;

      a {
        font-family: 'PT Sans', Arial, sans-serif;
        position: relative;
        display: block;
        font-size: 24px;
        color: #A49A96;
        letter-spacing: 0;
        margin-top: 10px;

        &.active {
          color: #D69543;
        }

        &.sub {
          font-size: 20px;
          color: #A49A96;
          letter-spacing: 0;
          line-height: 22px;
          margin-top: 10px;
          margin-left: 10px;

          &.active {
            color: #D69543;
          }
        }
      }
      img {
        height: 10px;
        width: 16px;
        position: absolute;
        right: 26px;
        top: 38px;
        transition: transform 300ms ease;
        display: none;
      }

      &.active {
        max-height: 100vh;
        img {
          display: block;
        }
      }
    }
    &.active {
      max-height: 100vh;
      width: 100vw;
      padding-bottom: 160px;
    }
  }


  nav {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.8;
    letter-spacing: normal;

    a {
      color: $mushroom;
      font-family: 'PT Sans', Arial, sans-serif;

      &:hover, {
        color: $greyish-brown-two;
        text-decoration: none;
      }
      &.active {
        color: $dull-orange;
      }
    }
    span {
      color: $dull-orange;
    }
  }
}

#toggle-menu {
  position: absolute;
  top: 50px;
  right: 15px;
  width: 39px;
  height: 25px;
  content: ' ';
  background-image: url(/dist/img/hamburger-menu@2x.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  cursor: pointer;
  z-index: 98;
  @media (min-width: 992px) {
    display: none;
  }
}

.logo-mobile {
  width: 200px;
  height: auto;
  position: absolute;
  top: 25px;
}

nav.desktop,
.submenu {
  display: none;

  a.active {
    color: $dull-orange;
  }
}

@media (min-width: 992px) {
  nav.desktop {
    display: block;
    text-align: right;
  }

  .submenu {
    display: flex;
  }

  nav.mobile {
    display: none;
    padding-bottom: 0;
  }

  #open-menu,
  #close-menu {
    display: none;
  }

  #navigation .social {
    display: block;
  }
}
