#header {
  margin-top: 50px;
  @media (max-width: 992px) {
    margin-top: 0;
    position: fixed;
    z-index: 1;
  }

  .claim {
    float: left;
    margin-top: 50px;
    @media (max-width: 992px) {
      display: none;
    }

    @media (min-width: 992px) {
      margin-top: 0;
    }
    div {
      text-align: right;
      @media (max-width: 992px) {
        text-align: center;
      }
      a {
        font-size: 38px;
        color: $mushroom;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        font-family: "PT Sans", Arial, sans-serif;

        @media (min-width: 992px) {
          font-size: 24px;
          font-stretch: normal;
          text-decoration: none;
        }
        &:hover {
          color: $dull-orange;
          cursor: pointer;
        }
      }

      span {
        font-weight: bold;
      }
    }
  }

  .submenu {
    padding-left: 15px;
    .wir {
      margin-bottom: auto;
      margin-top: 5px;
    }
    .ihre-stimme {
      margin-top: 35px;
      margin-bottom: auto;
    }
    .erfolgskurs {
      margin-top: 69px;
    }
  }

  .logo {
    text-align: right;
  }
}


body#startseite #header .claim div a {
  color: $dull-orange;

  &:hover {
    color: $mushroom;
  }
}

@media (min-width: 1007px) and (max-width: 1215px) {
  #header .claim div a {
    font-size: 20px;
  }
  .submenu {
    padding-left: 0px!important;
    font-size: 15px;
    letter-spacing: -0.3px;
    .wir {
      margin-top: 2px!important;
    }
    .ihre-stimme {
      margin-top: 28px!important;
    }
    .erfolgskurs {
      margin-top: 54px!important;
    }
  }
}
