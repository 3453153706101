footer {
  padding: 0 0 30px 0;
  margin-top: 150px;
  p {
    font-size: 14px;
    color: #A49A96;
    text-align: center;
    line-height: 16px;
    margin-bottom: 20px;
  }
}