/* pt-sans-regular - latin */
@font-face {
  font-family: 'PT Sans';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/pt-sans-v17-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/pt-sans-v17-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/pt-sans-v17-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/pt-sans-v17-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/pt-sans-v17-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/pt-sans-v17-latin-regular.svg#PTSans') format('svg'); /* Legacy iOS */
}
/* pt-sans-italic - latin */
@font-face {
  font-family: 'PT Sans';
  font-style: italic;
  font-weight: 400;
  src: url('../fonts/pt-sans-v17-latin-italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/pt-sans-v17-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/pt-sans-v17-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/pt-sans-v17-latin-italic.woff') format('woff'), /* Modern Browsers */
       url('../fonts/pt-sans-v17-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/pt-sans-v17-latin-italic.svg#PTSans') format('svg'); /* Legacy iOS */
}
/* pt-sans-700 - latin */
@font-face {
  font-family: 'PT Sans';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/pt-sans-v17-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/pt-sans-v17-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/pt-sans-v17-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/pt-sans-v17-latin-700.woff') format('woff'), /* Modern Browsers */
       url('../fonts/pt-sans-v17-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/pt-sans-v17-latin-700.svg#PTSans') format('svg'); /* Legacy iOS */
}
/* pt-sans-700italic - latin */
@font-face {
  font-family: 'PT Sans';
  font-style: italic;
  font-weight: 700;
  src: url('../fonts/pt-sans-v17-latin-700italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/pt-sans-v17-latin-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/pt-sans-v17-latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/pt-sans-v17-latin-700italic.woff') format('woff'), /* Modern Browsers */
       url('../fonts/pt-sans-v17-latin-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/pt-sans-v17-latin-700italic.svg#PTSans') format('svg'); /* Legacy iOS */
}

h1 {
  font-family: 'PT Sans', Arial, sans-serif;
  font-size: 26px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: $muted-blue;
  margin: 0 0 10px 0;
  span {
    font-family: 'PT Sans', Arial, sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    color: $greyish-brown-two;
    display: block;
  }
}

h2 {
  font-family: 'PT Sans', Arial, sans-serif;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  color: $muted-blue;
  margin: 20px 0 10px 0;
  span {
    font-family: 'PT Sans', Arial, sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    color: $greyish-brown-two;
    display: block;
  }
}

h3 {
  font-family: 'PT Sans', Arial, sans-serif;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  color: $muted-blue;
  margin: 20px 0 0 0;
  span {
    font-weight: normal;
  }
}

p {
  font-family: 'PT Sans', Arial, sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  color: $greyish-brown-two;
  .small {
    font-family: 'PT Sans', Arial, sans-serif;
    font-size: 14px;
    font-weight: bold;
    line-height: 1.29;
    color: $greyish-brown-two;
  }
}

strong {
  font-weight: bold;
}

a {
  text-decoration: none;
  color: $greyish-brown-two;
  &:hover {
    color: $muted-blue;
    text-decoration: none;
  }
}
