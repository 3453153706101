form {
  .field-collection {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;

    @media (max-width: 1002px) {
      flex-direction: column;
    }

    &.columns {
      flex-direction: row;
    }
  }
  input {
    width: 30%;
    border: none;
    background: #E0DCDA;
    border-radius: 10px;
    border-color: #A49A96;
    color: #5d514b;
    padding: 12px 16px;
    height: 44px;
    margin: 1.5%;
    font-size: 12px;
    -webkit-tap-highlight-color: rgba(0,0,0,0);

    @media (max-width: 1002px) {
      width: 100%;
    }
    &[type='checkbox'] {
      width: 20px;
      height: 1.2em;
    }

    &::placeholder {
      font-size: 12px;
      color: #A49A96;
    }
  }
  textarea {
    background: #E0DCDA;
    border-radius: 10px;
    border-color: #A49A96;
    color: #5d514b;
    padding: 12px 16px;
    min-height: 150px;
    width: 100%;
    margin: 1.5%;
  }
  button {
    background: transparent;
    border: none;
    color: $dull-orange;
    margin: 40px auto 0;
    transition: transform 300ms ease;
    background-color: #5d514b;
    border-radius: 5px;
    padding: 10px 20px;

    &:hover {
      transform: scale(1.07);
      transform-origin: center;
    }
  }
  .dates {
    input {
      width: 100%;
    }
  }
}

input:focus {outline: none;}
input:focus:required:invalid {border: 2px solid rgb(246, 111, 111);}
input:required:valid { border: 2px solid #A49A96; }
